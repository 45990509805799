import React from "react"

import Layout from "../components/layout"

import styles from "../styles/pages/mission-vision.module.css"

import mainimg from "../images/icon.png"

const MissionvisionPage = () => (
	<Layout activePath="mission-vision" pageTitle="Mission & Vision">
		<div className={"text-centered"}>
			<div className={styles.content+" color-background-gray-f3"}>
				<img src={mainimg} alt=""/>
				<h2 className="font-style-regular">Mission</h2>
				<div>
					We are committed to help develop the Philippine capital market and to give the best investment advice to our clients.
				</div>
			</div>
			<div className={styles.content+" color-background-gray-f3"}>
				<img src={mainimg} alt=""/>
				<h2 className="font-style-regular">Vision</h2>
				<div>
					We aim to be one of the most outstanding stock brokerage firms that offer excellent financial services in the Philippines.
				</div>
			</div>
		</div>
	</Layout>
)

export default MissionvisionPage
